import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import axios from "axios";
import { userAuthToken } from "../atoms/authToken";
import { useRecoilState, useRecoilValue } from "recoil";
import { apiRoute } from "../Globals";
import { useNavigate } from "react-router-dom";

export default function EditPin() {
  const navigate = useNavigate();
  const { token, pinid, user_id } = useParams();
  const userAuth = useRecoilValue(userAuthToken);
  const [loading, setLoading] = useState(true);
  const [pinData, setPinData] = useState({});
  const [color, setColor] = useState("");
  const [pinType, setPinType] = useState("");
  const [pinName, setPinName] = useState("");
  const [pinTitle, setPinTitle] = useState("");

  //fetch pin details
  useEffect(() => {
    try {
      axios
        .get(`${apiRoute}/data/pins/details/color/title/type`, {
          params: {
            token: token,
            user_id: userAuth,
            id: pinid,
          },
        })
        .then((Response) => {
          setLoading(false);
          const Result = Response.data.Result[0];
          setPinData(Result);
          console.log(Response);
          setColor(Response.data.Result[0].indicator_color);
          setPinType(Response.data.Result[0].pin_type);
          setPinName(Response.data.Result[0].pin_name);
          setPinTitle(Response.data.Result[0].pin_title);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      axios
        .post(`${apiRoute}/data/pins/update/${token}/${userAuth}/${pinid}`, {
          pin_title: pinTitle,
          indicator_color: color,
        })
        .then((res) => {
          navigate(`/dashboard/allpins/${token}/${user_id}`);
          alert("updated successfully");
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
          console.log(token);
          console.log(userAuth);
          console.log(pinid);
          console.log(color);
          console.log(apiRoute);
        });
    } catch (error) {
      var x = 0;
    }
  };

  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Pin</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/dashboard"}>Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/dashboard/allpins/${token}/${user_id}`}>All Pins</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to={`allpins/editpin/${token}/${pinid}/${user_id}`}>Edit Pin</Link>
              </li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="card">
            <div className="card-body pt-5">
              <h5 className="card-title">Update Pin Details:</h5>

              {loading ? (
                <div className="d-block">
                  <div
                    className="spinner-border text-primary my-3"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <form action="" onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Name"
                      value={pinName || ""}
                      disabled
                    />
                    <label htmlFor="floatingInput">{pinName}</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingtext1"
                      placeholder="Pin Title"
                      value={pinTitle}
                      // defaultValue={pinTitle || ''}
                      onChange={(e) => setPinTitle(e.target.value)}
                    />
                    <label htmlFor="floatingtext1">Pin Title</label>
                  </div>
                  {pinType === "Input" ? (
                    <div className="form-floating mb-3">
                      <input
                        type="color"
                        className="form-control"
                        id="colorPicker"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                      />
                      <label htmlFor="colorPicker">Select a color</label>
                      <br />
                      <p className="d-flex justify-content-end">
                        Indicator Example:&nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                          style={{
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            backgroundColor: `${color}`,
                          }}
                        ></div>
                      </p>
                    </div>
                  ) : null}

                  <button
                    type="submit"
                    onSubmit={handleSubmit}
                    className="btn btn-success float-end"
                  >
                    Update
                  </button>
                </form>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
