import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAuthToken } from "../atoms/authToken";
import { apiRoute } from "../Globals";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotificationSound from "./notification-sound.mp3";

export default function AllPins() {
  const notify = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifyFailure = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const playAudio = () => {
    audioPlayer.current.play();
  };

  const audioPlayer = useRef(null);
  const { token, user_id } = useParams();
  const [userAuth] = useRecoilState(userAuthToken);
  const [pinData, setPinData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pinValues, setPinValues] = useState({});
  const [pin, setPin] = useState({
    name: "",
    pinType: "",
    pinTitle: "",
    maxValue: "10",
    pinIndicator: "#00ff00",
    value: "0",
  });
  // const [pinSuccess, setPinSuccess] = useState(false);
  const [deviceName, setDeviceName] = useState(false);

  const isAdmin = userAuth === 26 ? true : false;

  const handleToggleChange = (pinName, isChecked) => {
    setPinValues((prevValues) => ({
      ...prevValues,
      [pinName]: isChecked ? "1" : "0",
    }));

    axios
      .post(`${apiRoute}/database-firebase/update-value`, {
        token: token,
        user_id: userAuth,
        pin_name: pinName,
        value: isChecked ? "1" : "0",
      })
      .then((response) => {
        setTimeout(() => {
          ResetOutput(pinName);
        }, 15000); // 15 seconds
      })
      .catch((error) => {});
  };

  const ResetOutput = (pinName) => {
    setPinValues((prevValues) => ({
      ...prevValues,
      [pinName]: "0",
    }));

    axios
      .post(`${apiRoute}/database-firebase/update-value`, {
        token: token,
        user_id: userAuth,
        pin_name: pinName,
        value: "0",
      })
      .then((response) => {})
      .catch((error) => {});
  };

  useEffect(() => {
    axios
      .get(`${apiRoute}/database-firebase/pin-data/${token}/${userAuth}`)
      .then((response) => {
        console.log(response);
        setPinData(response.data.Data);
        setDeviceName(response.data.Data[0].device_name);
        const updatedPinValues = {};
        for (const pin of response.data.Data) {
          if (pin.pin_type === "Reading") {
            const pinCal = Number(
              Number(pin.value) > Number(pin.max_value)
                ? pin.max_value
                : pin.value
            );
            updatedPinValues[pin.pin_name] = pinCal;
          } else {
            updatedPinValues[pin.pin_name] = pin.value;
          }
        }
        setPinValues(updatedPinValues);
        setLoading(false);
      })
      .catch((error) => {});

    const fetchData = () => {
      axios
        .get(`${apiRoute}/database-firebase/pin-data/${token}/${userAuth}`)
        .then((response) => {
          setPinData(response.data.Data);
          for (const pin of response.data.Data) {
            setPinValues((prevValues) => ({
              ...prevValues,
              [pin.pin_name]: pin.value,
            }));
            if (pin.notification === "1" && pin.pin_type === "Input") {
              axios
                .post(`${apiRoute}/notification/update`, {
                  token: token,
                  pin_name: pin.pin_name,
                })
                .then((response) => {
                  notify(
                    "Value changed for pin " +
                      pin.pin_title +
                      " having device token " +
                      pin.token
                  );
                  playAudio();
                })
                .catch((error) => {});
            }
          }
          setLoading(false);
        })
        .catch((error) => {});
    };

    const throttledFetchData = _.throttle(fetchData, 3000);

    const intervalId = setInterval(() => {
      throttledFetchData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [token, userAuth]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPin((prevData) => ({
      ...prevData,
      [name]: name === "name" ? value.toUpperCase() : value,
      ...(name === "title" && { pinTitle: value }),
    }));
  };

  const handlePinDetail = (e) => {
    e.preventDefault();
    const check = pin.pinType === "Output" ? "0" : "0.00";

    axios
      .post(`${apiRoute}/database-firebase/add-pins/${token}`, {
        pin_name: pin.name,
        pin_type: pin.pinType,
        value: check,
        user_id: user_id,
        pin_title: pin.pinTitle,
        indicator_color: "#00ff00",
        max_value: pin.maxValue,
      })
      .then((response) => {
        notify("Pin " + pin.name + " successfully created.");
        // setPinSuccess(true);
        // setPinFailure(false);
        console.log("pin created successfully:");
        console.log(response);
        setPin({
          name: "",
          pinType: "",
          pinTitle: "",
          max_value: "10",
          value: "0",
        });
      })
      .catch((e) => {
        console.log(e.response.data.Result);
        const errors = e.response.data.Result;

        // Construct the failure notification
        let errorMessage = "Pin creation failed due to:";
        if (errors.pin_name && errors.pin_name.length > 0) {
          errorMessage += " " + errors.pin_name[0];
        }
        if (errors.pin_title && errors.pin_title.length > 0) {
          errorMessage += " " + errors.pin_title[0];
        }
        if (errors.pin_type && errors.pin_type.length > 0) {
          errorMessage += " " + errors.pin_type[0];
        }

        notifyFailure(errorMessage);
        // setPinFailure(true);
        // setPinSuccess(false);
      });
  };

  return (
    <div>
      <main id="main" className="main">
        <ToastContainer />
        <audio ref={audioPlayer} src={NotificationSound} />
        {/* <div>
          {pinSuccess && (
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              Pin added successfully!
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={()=>setPinFailure(false)}></button>
            </div>
          )}
          {pinFailure && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              Failed to add pin. Please try again.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"onClick={()=>setPinFailure(false)}></button>
            </div>
          )}
        </div> */}
        <div className="pagetitle">
          <h1>UPS PARAMETERS</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <li>
                  <Link to={"/dashboard"}>Home</Link>
                </li>
              </li>
              <li className="breadcrumb-item active">
                <Link to={`/dashboard/allpins/${token}`}>UPS PARAMETERS</Link>
              </li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="card">
            <div className="card-body pt-5">
              {isAdmin && (
                <>
                  <div className="my-2">
                    Pins For Device of User {user_id} with token {token}
                    <br />
                    UPS Serial Number: {deviceName}
                  </div>
                  <div className="d-grid gap-2 mb-5">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#modalDialogScrollable2"
                    >
                      + Add New Pin
                    </button>
                  </div>
                </>
              )}
              {!isAdmin && (
                <>
                  <div className="my-2">UPS Serial Number: {deviceName}</div>
                </>
              )}
              <form onSubmit={handlePinDetail}>
                <div
                  className="modal fade"
                  id="modalDialogScrollable2"
                  tabIndex="-1"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Give Pin Details</h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row mb-3">
                          <div className="col-sm-10">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Name"
                                name="name"
                                value={pin.name}
                                onChange={handleChange}
                              />
                              <label htmlFor="floatingInput">Pin Name</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Pin Title"
                                name="title"
                                value={pin.pinTitle}
                                onChange={handleChange}
                              />
                              <label htmlFor="floatingInput">Pin Title</label>
                            </div>
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                id="pinType"
                                name="pinType"
                                value={pin.pinType}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Choose Pin Type
                                </option>
                                <option value="Input">Input</option>
                                <option value="Output">Output</option>
                                <option value="Reading">Analog</option>
                              </select>
                              <label htmlFor="pinType">Pin Type</label>
                            </div>
                            {pin.pinType === "Reading" && (
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Max Value"
                                  name="maxValue"
                                  value={pin.maxValue}
                                  onChange={handleChange}
                                />
                                <label htmlFor="floatingInput">Max Value</label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="row justify-content-around">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-primary" type="button">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp; Loading ...
                    </button>
                  </div>
                ) : pinData.length === 0 ? (
                  <p>No pins available.</p>
                ) : (
                  <table className="table table-striped">
                    <thead>
                      <tr className="text-center">
                        <th>#</th>
                        {isAdmin && (
                          <>
                            <th>Name</th>
                            <th>Value</th>
                          </>
                        )}
                        <th>Title</th>
                        <th>Status</th>
                        {isAdmin && <th>Edit</th>}
                        <th>Log Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pinData.slice(1).map((pin, index) => (
                        <tr className="text-center" key={pin.id}>
                          <th scope="row">{index + 1}</th>
                          {isAdmin && (
                            <>
                              <td>{pin.pin_name}</td>
                              <td>{pinValues[pin.pin_name]}</td>
                            </>
                          )}
                          <td>{pin.pin_title}</td>
                          <td className="">
                            <div className="d-flex justify-content-center align-items-center">
                              {pin.pin_type === "Output" ? (
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`toggle-${pin.id}`}
                                    checked={
                                      pinValues[pin.pin_name] === "1"
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleToggleChange(
                                        pin.pin_name,
                                        e.target.checked
                                      )
                                    }
                                  />
                                </div>
                              ) : pin.pin_type === "Input" ? (
                                <fieldset className="row mb-3">
                                  <div className="col-sm-10 d-flex">
                                    {pinValues[pin.pin_name] === "0.00" ? (
                                      <div className="">
                                        <div
                                          style={{
                                            borderRadius: "50%",
                                            width: "35px",
                                            height: "35px",
                                            backgroundColor: "#ffffff",
                                            border: "1px solid black",
                                          }}
                                        ></div>
                                      </div>
                                    ) : (
                                      <div className="">
                                        <div
                                          style={{
                                            borderRadius: "50%",
                                            width: "35px",
                                            height: "35px",
                                            backgroundColor: `${pin.indicator_color}`,
                                            border: "1px solid black",
                                          }}
                                        ></div>
                                      </div>
                                    )}
                                  </div>
                                </fieldset>
                              ) : (
                                <span className="badge bg-light text-dark px-4 py-1">
                                  {pinValues[pin.pin_name]}
                                </span>
                              )}
                            </div>
                          </td>
                          {isAdmin && (
                            <td>
                              <Link
                                to={`/dashboard/allpins/editpin/${pin.token}/${pin.id}/${user_id}`}
                                className="btn btn-outline-success "
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </Link>
                            </td>
                          )}
                          <td>
                            <Link
                              to={`/dashboard/allpins/pinLog/${pin.token}/${pin.id}/${pin.pin_type}/${user_id}`}
                              className="btn btn-outline-primary "
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
