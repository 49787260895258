import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { userAuthToken, userName, admin } from "../atoms/authToken";
import { useRecoilValue } from "recoil";
import { apiRoute } from "../Globals";
import { ToastContainer, toast } from "react-toastify";
import { useDownloadExcel } from "react-export-table-to-excel";

export default function Main() {
  //define state variables
  const authToken = useRecoilValue(userAuthToken);
  const username = useRecoilValue(userName);
  const [deviceData, setDeviceData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [deviceToken, setDeviceToken] = useState("");
  const [deviceUserId, setDeviceUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(true);
  const [pinSuccess, setPinSuccess] = useState(false);
  const [pinFailure, setPinFailure] = useState(false);
  const [deviceSuccess, setDeviceSuccess] = useState(false);
  const [deviceFailure, setDeviceFailure] = useState(false);

  const tableref = useRef(null);

  const [search, setSearch] = useState("");

  const isAdmin = authToken === 26 ? true : false;

  const [formData, setFormData] = useState({
    name: "",
    deviceType: "",
    userId: "",
    description: "",
  });

  const [pin, setPin] = useState({
    name: "",
    pinType: "",
    pinTitle: "",
    pinIndicator: "#ffffff",
    value: "0",
    maxValue: "10",
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableref.current,
    filename: "npsdata",
    sheet: "npsData",
  });

  //handle change in the form data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePinChange = (e) => {
    const { name, value } = e.target;

    setPin((prevData) => ({
      ...prevData,
      // [name]: value,
      [name]: name === "name" ? value.toUpperCase() : value,
      ...(name === "title" && { pinTitle: value }),
    }));
  };

  const notify = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifyFailure = (msg) => {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  //fetch all device details

  useEffect(() => {
    try {
      axios
        .get(`${apiRoute}/fireplusdb-userid/${authToken}`)
        .then((Response) => {
          setLoading(false);
          console.log(Response);
          const status = Response.status;
          if (status === 200) {
            setDeviceData(Response.data.Result);
            setNotFound(false);
          } else if (status === 404) {
            setNotFound(true);
            // console.log(Response.data.Result); // Log the error message
          }
        })
        .catch((error) => {
          setLoading(false);
          setNotFound(true);
          console.error("Error:", error); // Log any other errors
        });
    } catch (error) {
      setLoading(false);
      setNotFound(true);
      console.error("Error:", error);
    }
  }, [authToken]);

  // fetch all users
  useEffect(() => {
    try {
      axios
        .get(`${apiRoute}/get-all-users`)
        .then((Response) => {
          // console.log(Response);
          const status = Response.status;
          if (status === 200) {
            setUserData(Response.data.data);
            setNotFound(false);
          } else if (status === 404) {
            setNotFound(true);
            console.log(Response.data.data); // Log the error message
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);

          console.error("Error:", error); // Log any other errors
        });
    } catch (error) {
      setLoading(false);

      console.error("Error:", error);
    }
  }, [authToken]);

  // create a new pin
  const handlePinDetail = (e) => {
    console.log(pin);
    e.preventDefault();
    axios
      .post(`${apiRoute}/database-firebase/add-pins/${deviceToken}`, {
        pin_name: pin.name,
        pin_type: pin.pinType,
        value: pin.value,
        user_id: deviceUserId,
        pin_title: pin.pinTitle,
        indicator_color: "#ffffff",
        max_value: pin.maxValue,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          notify("Pin " + pin.name + " successfully created.");
          // setPinSuccess(true);
          // setPinFailure(false);
        }
      })
      .catch((e) => {
        let errorMessage = "Pin creation failed due to:";
        const errors = e.response.data.Result;

        if (errors.pin_name && errors.pin_name.length > 0) {
          errorMessage += " " + errors.pin_name[0];
        }
        if (errors.pin_title && errors.pin_title.length > 0) {
          errorMessage += " " + errors.pin_title[0];
        }
        if (errors.pin_type && errors.pin_type.length > 0) {
          errorMessage += " " + errors.pin_type[0];
        }

        notifyFailure(errorMessage);
        // setPinFailure(true);
        // setPinSuccess(false);
        console.error("Error:", e);
      });
    setPin({
      name: "",
      pinType: "",
      pinTitle: "",
      maxValue: "10",
      pinIndicator: "##00ff00",
      value: "0",
    });
  };
  //handle submit and post data to the api
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);

    // try {
    axios
      .post(`${apiRoute}/database-entry`, {
        name: formData.name,
        device: formData.deviceType,
        user_id: formData.userId,
        description: formData.description,
      })
      // }).then((Response) => {
      //     console.log(Response);
      // })
      .then((response) => {
        console.log(response);
        // After successfully adding a device, update the deviceData state
        if (response.status === 200) {
          notify("Device " + formData.name + " successfully created.");

          const newDevice = {
            id: response.data.id,
            name: formData.name,
            device: formData.deviceType,
            description: formData.description,
            created: response.data.created_at,
            token: response.data.token,
            status: response.data.status,
          };
          setDeviceData([...deviceData, newDevice]);
          // Alternatively, if you want to add the new device to the existing data, you can do:
          // setDeviceData([...deviceData, formData]);
        }
      })
      .catch((e) => {
        notifyFailure("Failed to Create Device" + formData.name);
        // setDeviceFailure(true);
        // setDeviceSuccess(false);
        console.error("Error:", e);
      });

    setFormData({
      name: "",
      deviceType: "",
      description: "",
    });
  };

  //Delete the device
  const handleDelete = (id) => {
    axios
      .delete(`${apiRoute}/database-firebase/delete/${id}`)
      .then((response) => {
        console.log(response);
        if (response.data.Status === 200) {
          window.location.reload();
          // alert("successfully deleted");
        }
      });
  };
  //Activate the device
  const handleActivate = (id) => {
    axios
      .post(`${apiRoute}/database-firebase/activate/${id}`)
      .then((response) => {
        console.log(response);
        if (response.data.Status === 200) {
          window.location.reload();
          // alert("successfully activated");
        }
      });
  };

  deviceData.sort((a, b) => new Date(b.created) - new Date(a.created));
  return (
    <div>
      <main id="main" className="main">
        <ToastContainer />
        <div className="pagetitle">
          <h1>Dashboard</h1>

          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <li>
                  <Link to={"./"}>Home</Link>
                </li>
              </li>
              <li className="breadcrumb-item active">
                <Link to={"./"}>Dashboards</Link>
              </li>
            </ol>
          </nav>
        </div>
        <div>
          {deviceSuccess && (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              Device added successfully!
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          {deviceFailure && (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              Failed to add device. Please try again.
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          {pinSuccess && (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              Pin added successfully!
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          {pinFailure && (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              Failed to add pin. Please try again.
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
        </div>

        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="row">
            <div className="card">
              <div className="card-body pt-5">
                <h5>
                  {" "}
                  Hello! {username}, Your GMT_UID is {authToken}
                </h5>

                {authToken === 26 && (
                  <div className="d-grid gap-2 m-3 my-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#modalDialogScrollable"
                    >
                      Add New UPS +
                    </button>
                  </div>
                )}
                <input
                  type="text"
                  placeholder="Search Serial Number"
                  class="form form-control my-2"
                  onChange={(e) => setSearch(e.target.value)}
                ></input>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <button class="btn btn-primary" onClick={onDownload}>
                    Export Excel
                  </button>
                  <table className="table table-striped" ref={tableref}>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">UPS Serial No</th>
                        {authToken === 26 && (
                          <>
                            <th scope="col">User ID </th>
                            <th scope="col">User Name</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Status</th>
                            <th scope="col">UPS Token</th>
                          </>
                        )}
                        <th scope="col">UPS Type</th>
                        <th scope="col">Site Address</th>

                        <th scope="col">View</th>
                        {isAdmin && <th scope="col">Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <div className="d-block">
                          <div
                            className="spinner-border text-primary my-3"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : notFound ? (
                        <p>No devices available.</p>
                      ) : (
                        <>
                          {deviceData
                            .sort((a, b) => {
                              // Sort by status so that "Active" devices come first
                              if (
                                a.status === "Active" &&
                                b.status !== "Active"
                              ) {
                                return -1;
                              } else if (
                                a.status !== "Active" &&
                                b.status === "Active"
                              ) {
                                return 1;
                              } else {
                                return 0;
                              }
                            })
                            .filter((device) => {
                              return search.toLowerCase() === ""
                                ? device
                                : device.name.toLowerCase().includes(search);
                            })
                            .map((device, index) => (
                              <tr key={device.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{device.name}</td>
                                {authToken === 26 && (
                                  <>
                                    <td>{device.user_id}</td>
                                    <td>{device.user_name}</td>
                                    <td>{device.created}</td>
                                    <td>{device.status}</td>
                                    <td>{device.token}</td>
                                  </>
                                )}
                                <td>{device.device}</td>
                                <td className="text-wrap">
                                  {device.description}
                                </td>

                                <td>
                                  {device.status === "Active" ? (
                                    <>
                                      {/* First two buttons for active devices */}
                                      {isAdmin && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-primary ms-1 mb-1"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modalDialogScrollable2"
                                          onClick={() => {
                                            setDeviceToken(device.token);
                                            setDeviceUserId(device.user_id);
                                          }}
                                        >
                                          <i className="fa-solid fa-plus"></i>
                                        </button>
                                      )}
                                      <Link
                                        to={`/dashboard/allpins/${device.token}/${device.user_id}`}
                                        className="btn btn-outline-primary ms-1 mb-1"
                                      >
                                        <i className="fa-regular fa-eye"></i>
                                      </Link>
                                    </>
                                  ) : (
                                    <button
                                      type="button"
                                      disabled
                                      className="btn btn-dark ms-1 mb-1"
                                    >
                                      Deactivated
                                    </button>
                                  )}
                                </td>
                                {isAdmin && (
                                  <>
                                    <td>
                                      {device.status === "Active" ? (
                                        <>
                                          {/* Last two buttons for active devices */}
                                          <Link
                                            to={`/dashboard/updatetemplate/${device.id}/${device.token}`}
                                            className="btn btn-outline-success ms-1 mb-1"
                                          >
                                            <i className="fa-solid fa-pen-to-square"></i>
                                          </Link>
                                          <button
                                            className="btn btn-outline-danger ms-1 mb-1"
                                            onClick={() =>
                                              handleDelete(device.id)
                                            }
                                          >
                                            <i className="fa-solid fa-trash"></i>
                                          </button>
                                        </>
                                      ) : (
                                        // Disabled buttons for inactive devices
                                        <>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleActivate(device.id)
                                            }
                                            className="btn btn-primary ms-1 mb-1"
                                          >
                                            Reactivate
                                          </button>
                                        </>
                                      )}
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div
              className="modal fade"
              id="modalDialogScrollable"
              tabIndex="-1"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Basic Information</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row mb-3">
                      <div className="col-sm-10">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          <label htmlFor="floatingInput">
                            UPS Serial Number
                          </label>
                        </div>

                        <div className="form-floating mb-3">
                          <select
                            type="text"
                            className="form-select"
                            id="floatingtext"
                            placeholder="Device Type"
                            name="deviceType"
                            value={formData.deviceType}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Choose UPS Type
                            </option>
                            <option value="ONLINE UPS">ONLINE UPS</option>
                            <option value="OFFLINE UPS">OFFLINE UPS</option>
                          </select>
                          <label htmlFor="floatingtext">UPS Type</label>
                        </div>
                        <div className="form-floating mb-3">
                          <select
                            type="text"
                            className="form-select "
                            id="floatingtext"
                            placeholder="123"
                            name="userId"
                            value={formData.userId}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Choose User
                            </option>
                            {userData
                              .slice()
                              .reverse()
                              .map((user, index) => (
                                <option value={user.id}>
                                  {user.name} ({user.id})
                                </option>
                              ))}
                          </select>
                          <label htmlFor="floatingtext">User Id</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingtext"
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                          <label htmlFor="floatingtext">Remarks</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      onSubmit={handleSubmit}
                      data-bs-dismiss="modal"
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <form onSubmit={handlePinDetail}>
            <div
              className="modal fade"
              id="modalDialogScrollable2"
              tabIndex="-1"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Give Pin Details</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row mb-3">
                      <div className="col-sm-10">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Name"
                            name="name"
                            value={pin.name}
                            onChange={handlePinChange}
                          />
                          <label htmlFor="floatingInput">Pin Name</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Pin Title"
                            name="title"
                            value={pin.pinTitle}
                            onChange={handlePinChange}
                          />
                          <label htmlFor="floatingInput">Pin Title</label>
                        </div>
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            id="pinType"
                            name="pinType"
                            value={pin.pinType}
                            onChange={handlePinChange}
                          >
                            <option value="" disabled>
                              Choose Pin Type
                            </option>{" "}
                            {/* Placeholder */}
                            <option value="Input">Input</option>
                            <option value="Output">Output</option>
                            <option value="Reading">Analog</option>
                          </select>
                          <label htmlFor="pinType">Pin Type</label>
                        </div>
                        {pin.pinType === "Reading" && ( // Conditionally render when pinType is "Analog"
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Max Value"
                              name="maxValue"
                              value={pin.maxValue}
                              onChange={handlePinChange}
                            />
                            <label htmlFor="floatingInput">Max Value</label>
                          </div>
                        )}
                        {/* {pin.pinType === 'Input' && (
                                                    <div className="form-floating mb-3">
                                                        <select
                                                            className="form-select"
                                                            id="value"
                                                            name="value"
                                                            value={pin.value}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                        </select>
                                                        <label htmlFor="value">Value</label>
                                                    </div>
                                                )} */}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      onSubmit={handlePinDetail}
                      data-bs-dismiss="modal"
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </main>
    </div>
  );
}
