import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userAuthToken, userName, admin } from "../atoms/authToken";
import { useRecoilState } from "recoil";
import { apiRoute } from "../Globals";

export default function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [userAuth, setUserAuth] = useRecoilState(userAuthToken);
  const [username, setUserName] = useRecoilState(userName);
  const [isAdmin, setIsAdmin] = useRecoilState(admin);
  const [inputError, setInputError] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${apiRoute}/auth/login`, {
        email: email,
        password: password,
      })
      .then((Response) => {
        console.log(Response);
        const { id } = Response.data.user;
        const { name } = Response.data.user;

        // Update userAuth after successful login
        setUserAuth(id);
        setUserName(name);

        // Check userAuth and navigate inside the .then block

        if (id !== "0") {
          if (id === 26) {
            setIsAdmin(true);
          }
          navigate("/dashboard");
        } else {

          alert("You are not a registered user. Please contact Admin to register yourself.");
        }
      })
      // .catch((error) => {
      //     console.log(error);
      //     if (error.response) {
      //         if (error.response.data.status === 422) { setInputError(error.response.data.error); console.log(inputError) }
      //     }

      // })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.error) {
          setInputError(error.response.data.error);
        } else if (error.response && error.response.data) {
          setInputError(error.response.data);
        } else {
          setInputError("An unknown error occurred.");
        }
      })
      .finally(() => {
        // Clear form fields after submission, whether success or failure
        setEmail("");
        setPassword("");
      });
  };

  return (
    <div>
      <main>
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <a
                      href="index.html"
                      className=" d-flex align-items-center w-auto"
                    >
                      <img src="assets/img/image.png" alt="" width={170} className="img-fluid"/>
                      {/* <span className="d-none d-lg-block">{userAuth}</span> */}
                    </a>
                  </div>
                  {inputError && (
                    <div
                      className="alert alert-danger alert-dismissible fade show text-center"
                      role="alert"
                    >
                      {inputError.email || inputError.password ? (
                        <>
                          <p>{inputError.email}</p>
                          <p>{inputError.password}</p>
                        </>
                      ) : (
                        <p>{inputError}</p>
                      )}
                      <button
                        type="button"
                        className="btn-close mx-2"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Login to Your Account
                        </h5>
                        <p className="text-center small">
                          Enter your username & password to login
                        </p>
                      </div>

                      <form
                        onSubmit={handleSubmit}
                        className="row g-3 needs-validation"
                        noValidate
                      >
                        <div className="col-12">
                          {/* <label htmlFor="yourEmail" className="form-label">E-Mail</label> */}
                          {/* <div className="input-group has-validation"> */}
                          <div className="form mb-4 text-start">
                            <label
                              htmlFor="floatingInput"
                              className="form-label"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="name@example.com"
                              name="email"
                              value={email}
                            />
                          </div>
                          <div className="invalid-feedback">
                            Please enter your username.
                          </div>
                          {/* </div> */}
                        </div>

                        <div className="col-12">
                          <div className="form mb-4 text-start">
                            <label
                              htmlFor="floatingPassword"
                              className="form-label"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              onChange={(e) => setPassword(e.target.value)}
                              id="floatingPassword"
                              placeholder="Password"
                              value={password}
                            />
                          </div>
                          {/* <label htmlFor="yourPassword" className="form-label">Password</label>
                                                    <input type="password" name="password" className="form-control" id="yourPassword" required />
                                                    <div className="invalid-feedback">Please enter your password!</div> */}
                        </div>

                        <div className="col-12">
                          <button
                            onClick={handleSubmit}
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                        {/* <div className="col-12">
                          <p className="small mb-0">
                            Don't have account?{" "}
                            <a href="/register">Create an account</a>
                          </p>
                        </div> */}
                      </form>
                    </div>
                  </div>

                  <div className="credits">
                    Designed by{" "}
                    <a href="https://growmoretech.in">Growmoretech</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
