import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { userAuthToken, userName } from "../atoms/authToken";
import { useRecoilValue } from "recoil";
import { apiRoute } from "../Globals";

export default function DeviceDetails() {
  //define state variables
  const authToken = useRecoilValue(userAuthToken);
  const username = useRecoilValue(userName);
  const userToken = useRecoilValue(userAuthToken);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${apiRoute}/device/details/${userToken}`)
      .then((response) => {
        console.log(response);
        setData(response.data.Data);
      })
      .catch((error) => {
        console.log(error);
      });
  });
  const handleDelete = (id) => {
    axios
      .delete(`${apiRoute}/device/destroy/${userToken}/${id}`)
      .then((response) => {
        console.log(response);
        // window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (authToken !== 26) {
      navigate("/dashboard");
    }
  });

  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Device Details</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <li>
                  <Link to={"./"}>Home</Link>
                </li>
              </li>
              <li className="breadcrumb-item active">
                <Link to={"/dashboard/devicedetails"}>UPS Details</Link>
              </li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="row">
            <div className="card">
              <div className="card-body pt-5">
                <h5> Hello! {username}</h5>
                <div className="d-grid gap-2 m-3 my-4">
                  <Link
                    className="btn btn-primary"
                    to={"/dashboard/devicedetailsform/add/:id"}
                  >
                    Add New UPS Details +
                  </Link>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="px-4" scope="col">
                          Si No.
                        </th>
                        <th className="px-4" scope="col">
                          Site
                        </th>
                        <th className="px-4" scope="col">
                          Action
                        </th>
                        <th className="px-4" scope="col">
                          Date
                        </th>
                        <th className="px-4" scope="col">
                          Batt.
                        </th>
                        <th className="px-4" scope="col">
                          IGBT
                        </th>
                        <th className="px-4" scope="col">
                          Card_1
                        </th>
                        <th className="px-4" scope="col">
                          Card_2
                        </th>
                        <th className="px-4" scope="col">
                          Card_3
                        </th>
                        <th className="px-4" scope="col">
                          Card_4
                        </th>
                        <th className="px-4" scope="col">
                          Transformer
                        </th>
                        <th className="px-4" scope="col">
                          Field_1
                        </th>
                        <th className="px-4" scope="col">
                          Field_2
                        </th>
                        <th className="px-4" scope="col">
                          Field_3
                        </th>
                        <th className="px-4" scope="col">
                          Field_4
                        </th>
                        <th className="px-4" scope="col">
                          Field_5
                        </th>
                        <th className="px-4" scope="col">
                          Field_6
                        </th>
                        <th className="px-4" scope="col">
                          Field_7
                        </th>
                        <th className="px-4" scope="col">
                          Field_8
                        </th>
                        <th className="px-4" scope="col">
                          Field_9
                        </th>
                        <th className="px-4" scope="col">
                          Field_10
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <div className="d-block">
                          <div
                            className="spinner-border text-primary my-3"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : notFound ? (
                        <p>No devices available.</p>
                      ) : (
                        <>
                          {data.map((data, index) => (
                            <tr>
                              <th className="px-4" scope="col">
                                {data.serial_number}
                              </th>
                              <td className="px-4">{data.site}</td>
                              <td className="px-4">
                                <Link
                                  to={`/dashboard/devicedetailsform/edit/${data.id}`}
                                  className="btn btn-outline-success ms-1 mb-1"
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </Link>
                                <button
                                  className="btn btn-outline-danger ms-1 mb-1"
                                  onClick={() => handleDelete(data.id)}
                                >
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </td>
                              <td className="px-4">
                                {data.date.substr(0, 10)}
                              </td>
                              <td className="px-4">{data.batt}</td>
                              <td className="px-4">{data.igbt}</td>
                              <td className="px-4">{data.card_1}</td>
                              <td className="px-4">{data.card_2}</td>
                              <td className="px-4">{data.card_3}</td>
                              <td className="px-4">{data.card_4}</td>
                              <td className="px-4">{data.transformer}</td>
                              <td className="px-4">{data.field_1}</td>
                              <td className="px-4">{data.field_2}</td>
                              <td className="px-4">{data.field_3}</td>
                              <td className="px-4">{data.field_4}</td>
                              <td className="px-4">{data.field_5}</td>
                              <td className="px-4">{data.field_6}</td>
                              <td className="px-4">{data.field_7}</td>
                              <td className="px-4">{data.field_8}</td>
                              <td className="px-4">{data.field_9}</td>
                              <td className="px-4">{data.field_10}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
