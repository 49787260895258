import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    key: 'recoil-persist', // this key is using to store data in local storage
    storage: localStorage, // configure which storage will be used to store the data
    converter: JSON // configure how values will be serialized/deserialized in storage
  })

export const userAuthToken = atom({ key: "userAuthToken", default: "" ,  effects_UNSTABLE: [persistAtom]
});
export const userName = atom({ key: "userName", default: "User_Name" ,  effects_UNSTABLE: [persistAtom]
});
export const admin = atom({ key: "admin", default: false ,  effects_UNSTABLE: [persistAtom]
});
