import React from 'react'
import { Link } from 'react-router-dom'

export default function UpdatePins() {
    return (
        <div>
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Pin Details</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/dashboard"}>Home</Link></li>
                            <li className="breadcrumb-item "><Link to={"/dashboard/alltemplate"}>All Templates</Link></li>
                            <li className="breadcrumb-item active"> <Link to={"/dashboard/updatepins"}> Pin Details</Link></li>
                        </ol>
                    </nav>
                </div>

                <section className="section dashboard" style={{height:"87%"}}>



                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">General Form Elements</h5>

                            <form action="">

                                <div className="row">

                                    <div className="col-lg-6 col-sm-12">

                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 0</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><Link to={"/dashboard/pinmodule"}className="btn btn-primary"
                                                style={{color: "white"}}>Module</Link></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 1</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 2</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 3</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 4</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 5</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 6</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 7</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">

                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 8</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 9</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 10</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 11</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 12</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 13</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 14</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputText" className="col-sm-2 col-form-label">Pin 15</label>
                                            <div className="col-sm-5">
                                                <select className="form-select">
                                                    <option selected>I/O</option>
                                                    <option value="1">Input</option>
                                                    <option value="2">Output</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3"><button className="btn btn-primary"><Link to={"/dashboard/pinmodule"}
                                                style={{color: "white"}}>Module</Link></button></div>
                                        </div>
                                    </div>



                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
