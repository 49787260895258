import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { apiRoute } from "../Globals";

export default function UpdateTemplate() {
  const { id, token } = useParams();
  const [name, setName] = useState("");
  const [device, setDevice] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [address, setAddress] = useState("");

  //fetch all device details
  useEffect(() => {
    try {
      axios
        .get(`${apiRoute}/database-firebase/description/${id}/fetch`)
        .then((Response) => {
          console.log(Response);

          setName(Response.data.data.name);
          setDevice(Response.data.data.device);
          setDesc(Response.data.data.description);
          setCity(Response.data.data.city);
          setState(Response.data.data.state);
          setPin(Response.data.data.pin);
          setUserCompany(Response.data.data.user_company);
          setAddress(Response.data.data.address);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error); // Log any other errors
        });
    } catch (error) {
      console.error("Error:", error);
    }
  }, [id]);
  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      axios
        // .post(`${apiRoute}/database-firebase/description/${id}/update`, {
        .post(`${apiRoute}/metadata/insert/${token}`, {
          city: city,
          state: state,
          pin: pin,
          user_company: userCompany,
          address: address,
          description: desc,
        })
        .then((Response) => {
          console.log(Response);
          alert("updated successfully");
        })
        .catch((error) => {
          console.error("Error:", error); // Log any other errors
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "city":
        setCity(value);
        break;
      case "state":
        setState(value);
        break;
      case "pin":
        setPin(value);
        break;
      case "user_company":
        setUserCompany(value);
        break;
      case "address":
        setAddress(value);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Edit Device Details</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              {/* <li className="breadcrumb-item "><Link to="/dashboard/alltemplate">All Templates</Link></li> */}
              <li className="breadcrumb-item active">
                <Link to={"/dashboard/updatetemplate"}>
                  Edit Device Details
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="d-grid gap-2 mt-3">
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Edit Below</h5>

                  <ul
                    className="nav nav-tabs d-flex"
                    id="myTabjustified"
                    role="tablist"
                  >
                    <li className="nav-item flex-fill" role="presentation">
                      <button
                        className="nav-link w-100 active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-justified"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Basic Information
                      </button>
                    </li>
                    {/* <li className="nav-item flex-fill" role="presentation">
                                            <button className="nav-link w-100" id="profile-tab" data-bs-toggle="tab"
                                                data-bs-target="#profile-justified" type="button" role="tab"
                                                aria-controls="profile" aria-selected="false">Meta Information</button>
                                        </li> */}
                  </ul>
                  <div className="tab-content pt-2" id="myTabjustifiedContent">
                    <div
                      className="tab-pane fade show active"
                      id="home-justified"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row my-3 d-flex justify-content-center">
                        <div className="col-sm-8">
                          <form action="" onSubmit={handleSubmit}>
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Name"
                                disabled
                              />
                              <label htmlFor="floatingInput">{name}</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingtext1"
                                placeholder="Device Name"
                                disabled
                              />
                              <label htmlFor="floatingtext1">{device}</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingtext"
                                placeholder="Description"
                                value={desc}
                                onChange={(e) => {
                                  setDesc(e.target.value);
                                }}
                              />
                              <label htmlFor="floatingtext">Remarks</label>
                            </div>

                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="user_company"
                                placeholder="User Company"
                                value={userCompany}
                                onChange={handleInputChange}
                              />
                              <label htmlFor="user_company">User Company</label>
                            </div>

                            <div>
                              <label className="fs-5 my-3">Address:</label>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  placeholder="Address"
                                  value={address}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="address">Address</label>
                              </div>

                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="city"
                                  placeholder="City"
                                  value={city}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="city">City</label>
                              </div>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="state"
                                  placeholder="State"
                                  value={state}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="state">State</label>
                              </div>
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pin"
                                  placeholder="Postal Code"
                                  value={pin}
                                  onChange={handleInputChange}
                                />
                                <label htmlFor="pin">Postal Code</label>
                              </div>
                            </div>

                            <button
                              type="submit"
                              onSubmit={handleSubmit}
                              className="btn btn-success float-end"
                            >
                              Save
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>

                    {/* <div className="tab-pane fade" id="profile-justified" role="tabpanel"
                                            aria-labelledby="profile-tab">
                                            <div className="row my-3 d-flex justify-content-center">
                                                <div className="col-sm-8">
                                                    


                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
