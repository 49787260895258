import React from 'react'
import { Link } from 'react-router-dom'

export default function PinModule() {
    return (
        <div>
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Module</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/dashboard"}>Home</Link></li>
                            <li className="breadcrumb-item "><Link to={"/dashboard/alltemplate"}>All Templates</Link></li>
                            <li className="breadcrumb-item "><Link to={"/dashboard/updatepins"}>Pin Details</Link></li>
                            <li className="breadcrumb-item active"><Link to={"/dashboard/pinmodule"}>Module</Link></li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard" style={{height:"87%"}}>


                    <div className="row">

                        <div className="card">
                            <div className="card-body">

                                <div className="row justify-content-between">
                                    <div className="col-sm-5">
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Button</h5>
                                                <p className="card-text">
                                                    <div className="d-flex justify-content-center">

                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox"
                                                                id="flexSwitchCheckDefault"/>
                                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Switch
                                                                    input</label>
                                                        </div>
                                                    </div>
                                                </p>
                                                <p className="card-text"><a href="/" className="btn btn-primary">Select</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Status Light</h5>
                                                <p className="card-text">
                                                    <div className="d-flex justify-content-center">

                                                        <fieldset className="row mb-3">
                                                            <div className="col-sm-10 d-flex">
                                                                <div className="form-check mx-3">
                                                                    <input className="form-check-input" type="radio" name="gridRadios"
                                                                        id="gridRadios1" value="option1" checked=""
                                                                        style={{backgroundColor: "red"}}/>
                                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                                            Off
                                                                        </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="gridRadios"
                                                                        id="gridRadios2" value="option2"
                                                                        style={{backgroundColor: "green"}}/>
                                                                        <label className="form-check-label" htmlFor="gridRadios2">
                                                                            On
                                                                        </label>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </p>
                                                <p className="card-text"><a href="/" className="btn btn-primary">Select</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="card mt-3">
                                            <div className="card-body">
                                                <h5 className="card-title">Value Indicator</h5>
                                                <p className="card-text">
                                                    <div className="d-flex justify-content-center">

                                                        <h2>44 <span style={{fontSize: "16px"}}>units</span> </h2>
                                                    </div>
                                                </p>
                                                <p className="card-text"><a href="/" className="btn btn-primary">Select</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
