import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";
import { apiRoute } from "../Globals";

export default function ChangePassword() {
  // eslint-disable-next-line no-unused-vars
  const [inputErrorLists, setInputErrorLists] = useState({});
  const { userId, userName } = useParams();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  //Handle any change in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Client-side validation
    if (formData.password.length < 8) {
      setInputErrorLists({
        password: "The password must be at least 8 characters long.",
      });
      return; // Exit early if validation fails
    }

    axios
      .post(`${apiRoute}/auth/change-password/${userId}`, {
        password: formData.password,
        password_confirmation: formData.confirmPassword,
      })
      .then((Response) => {
        if (Response.data.status === "success") {
          alert("password changed successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 422) {
            setInputErrorLists(error.response.data.error);
          }
        } else {
          setInputErrorLists("An unknown error occurred.");
        }
      })
      .finally(() => {});
  };

  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Change Password</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/dashboard/alltemplate">All Users</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/dashboard/alltemplate">Change Password</Link>
              </li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="d-grid gap-2 mt-3">
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Change Password for User : {userName} (UID:{userId})
                  </h5>

                  <div className="col-9 mx-auto">
                    <form
                      onSubmit={handleSubmit}
                      className="row g-3 needs-validation"
                      novalidate
                    >
                      <div className="col-12">
                        <label htmlFor="Password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className={`form-control ${
                            inputErrorLists.password ? "is-invalid" : ""
                          }`}
                          value={formData.password}
                          onChange={handleChange}
                          id="Password"
                          required
                        />
                        {inputErrorLists.password && (
                          <div className="invalid-feedback">
                            {inputErrorLists.password}
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className={`form-control ${
                            inputErrorLists.confirmPassword ? "is-invalid" : ""
                          }`}
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          id="confirmPassword"
                          required
                        />
                        {inputErrorLists.confirmPassword && (
                          <div className="invalid-feedback">
                            {inputErrorLists.confirmPassword}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
