import "./App.css";
import Dashboard from "./components/Dashboard";
import LogIn from "./components/LogIn";
import Register from "./Pages/Register";
import Main from "./Pages/Main";
import UpdateTemplate from "./Pages/UpdateTemplate";
import UpdatePins from "./Pages/UpdatePins";
import PinModule from "./Pages/PinModule";
import AllTemplate from "./Pages/AllTemplate";
import ChangePassword from "./Pages/ChangePassword";
// eslint-disable-next-line no-unused-vars
import { Routes, Route, index } from "react-router-dom";
import AllPins from "./Pages/AllPins";
import PinLog from "./Pages/PinLog";
import EditPin from "./Pages/EditPin";
import DeviceDetails from "./Pages/DeviceDetails";
import DeviceDetailsForm from "./Pages/DeviceDetailsForm";


function App() {
  return (
    <>
      <Routes>
        <Route path="*" element={<LogIn />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Main />} />
          <Route path="alltemplate" element={<AllTemplate />} />
          <Route path="devicedetails" element={<DeviceDetails />} />
          <Route path="devicedetailsform/:action/:id" element={<DeviceDetailsForm />} />
          <Route path="register" element={<Register />} />
          <Route path="changepassword/:userId/:userName" element={<ChangePassword/>} />
          <Route
          
            path="updatetemplate/:id/:token"
            element={<UpdateTemplate />}
          />
          <Route
          
            path="allpins/editpin/:token/:pinid/:user_id"
            element={<EditPin />}
          />
          <Route path="allpins/:token/:user_id" element={<AllPins />} />
          <Route
          
            path="allpins/pinLog/:token/:pinid/:pintype/:user_id"
            element={<PinLog />}
          />
          <Route path="updatepins" element={<UpdatePins />} />
          <Route path="pinmodule" element={<PinModule />} />
        </Route>
      </Routes>
      {/* <LogIn/> */}
    </>
  );
}

export default App;
