import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { apiRoute } from "../Globals";

export default function Register() {
  //declare variables and state variables
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contacts: "",
    password: "",
  });
  const [inputErrorLists, setInputErrorLists] = useState({});

  //Handle any change in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //handle the submissions of the form
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${apiRoute}/auth/register`, {
        name: formData.name,
        email: formData.email,
        contacts: formData.contacts,
        password: formData.password,
      })
      .then((Response) => {
        console.log(Response);
        const { status } = Response.data;
        console.log(status);
        if (status === 200) {
          navigate("/dashboard/alltemplate");
        } else {
          alert("register unsuccusseful");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.data.status === 422) {
            setInputErrorLists(error.response.data.error);
            console.log(inputErrorLists);
          }
        }
      });

    // Reset form after submission
    setFormData({
      name: "",
      email: "",
      contacts: "",
      password: "",
    });
  };

  return (
    <div>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>All Users</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active"><Link to="/dashboard/alltemplate">All Users</Link></li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard" style={{ height: "87%" }}>
          <div className="d-grid gap-2 mt-3">

            <div className="row">

              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add New User</h5>


                  <div className="col-9 mx-auto">

                    <form
                      onSubmit={handleSubmit}
                      className="row g-3 needs-validation"
                      novalidate
                    >
                      <div className="col-12">
                        <label htmlFor="yourName" className="form-label">
                          User Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          className={`form-control ${inputErrorLists.name ? "is-invalid" : ""
                            }`}
                          value={formData.name}
                          onChange={handleChange}
                          id="yourName"
                          required
                        />
                        {inputErrorLists.name && (
                          <div className="invalid-feedback">
                            {inputErrorLists.name}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourEmail" className="form-label">
                          User Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          className={`form-control ${inputErrorLists.email ? "is-invalid" : ""
                            }`}
                          id="yourEmail"
                          onChange={handleChange}
                          value={formData.email}
                          required
                        />
                        {inputErrorLists.email && (
                          <div className="invalid-feedback">
                            {inputErrorLists.email}
                          </div>
                        )}
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourcontacts" className="form-label">
                          Contact
                        </label>
                        <div className="input-group has-validation">
                          {/* <span className="input-group-text" id="inputGroupPrepend">@</span> */}
                          <input
                            type="text"
                            name="contacts"
                            className={`form-control ${inputErrorLists.contacts ? "is-invalid" : ""
                              }`}
                            id="yourcontacts"
                            value={formData.contacts}
                            onChange={handleChange}
                            required
                          />
                          {inputErrorLists.contacts && (
                            <div className="invalid-feedback">
                              {inputErrorLists.contacts}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className={`form-control ${inputErrorLists.password ? "is-invalid" : ""
                            }`}
                          id="yourPassword"
                          onChange={handleChange}
                          value={formData.password}
                          required
                        />
                        {inputErrorLists.password && (
                          <div className="invalid-feedback">
                            {inputErrorLists.password}
                          </div>
                        )}
                      </div>
                      {/* 
                    <div className="col-12">
                      <div className="form-check">
                        <input className="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" required/>
                        <label className="form-check-label" htmlFor="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                        <div className="invalid-feedback">You must agree before submitting.</div>
                      </div>
                    </div> */}
                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Create User
                        </button>
                      </div>
                      {/* <div className="col-12">
                          <p className="small mb-0">
                            Already have an account?{" "}
                            <Link to={"/"}>Log in</Link>
                          </p>
                        </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

    </div>
  );
}
